<template>
    <div class="seongdong_curation_wrap genre" :class="getCurationCss">
        <ul class="setting_target_detail">
            <h2 class="curation_body_list_title"> 선호 장르 </h2>
            <ul class="curation_list">
                <ul class="curation_list mt0">
                    <li class="curation_list_item mb8" v-for="item in getGenreList" :key="item.id">
                        <label :for="item.id" class="curation_item"
                               :class="{ 'active': genre.includes(item.value), 'disabled': parseInt(domesticRate) === 0 }">{{
                                   item.koText
                               }}</label>
                        <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="genre">
                    </li>
                </ul>
            </ul>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'SeongDongCurationGenre',
  props: {
    genrearray: Array,
    domesticRate: String
  },
  data () {
    return {
      genre: this.genrearray.map(item => this.$store.getters["player/getCurationStoreGenre"](item))
    };
  },
  created () {

  },

  mounted () {

  },

  methods: {

  },
  computed: {
    getCurationCss () {
      return this.$store.getters.gettersCurationCssClass;
    },
    getGenreList () {
      return this.$store.getters['curation/getSeondongGenre'];
    }
  },
  watch: {
    genre: {
      deep: true,
      handler (val) {
        this.$emit('send-curation', {
          type: 'genre',
          value: val
        });
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/seongdonglibary/seongdongcuration.css"></style>
