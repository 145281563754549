import { render, staticRenderFns } from "./SeongDongCurationGenre.vue?vue&type=template&id=32008897&scoped=true"
import script from "./SeongDongCurationGenre.vue?vue&type=script&lang=js"
export * from "./SeongDongCurationGenre.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/seongdonglibary/seongdongcuration.css?vue&type=style&index=0&id=32008897&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32008897",
  null
  
)

export default component.exports